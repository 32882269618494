<template>
  <div class="page-table musteriTanim  mainDiv mb-30" id="affix-container">
    <div class="animated fadeInUp card-base baslikBg">BGÇelik Müşteri Güncelle</div>
    <el-row :gutter="10" v-loading="loading"
            :element-loading-text='"Müşteri detay bilgileri getiriliyor. Lütfen bekleyiniz."'
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 1)">
      <el-form status-icon :model="musteriForm" :rules="rulesMusteri" ref="musteriForm" class="demo-ruleForm">
        <el-col :lg="18" :md="18" :sm="24" :xs="24">
          <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-10 pt-10">
            <el-row>
              <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Firma Adı" prop="musteriAdi">:
                  <el-input size="small" v-model="musteriForm.musteriAdi"
                            :placeholder="'Firma Adı Giriniz'"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Firma Unvanı" prop="unvan">:
                  <el-input size="small" v-model="musteriForm.unvan"
                            :placeholder="'Firma Unvanı Giriniz'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Telefon" prop="telefon">:
                  <el-input size="small" v-mask="'+90(###)### ## ##'" v-model="musteriForm.telefon"
                            :placeholder="'Telefon Numarası Giriniz'"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                <el-form-item label="E-posta" prop="eposta">:
                  <el-input size="small" v-model="musteriForm.eposta"
                            :placeholder="'E-posta Giriniz'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Vergi Dairesi" prop="vergiDairesi">:
                  <el-input size="small" v-model="musteriForm.vergiDairesi"
                            :placeholder="'Vergi Dairesi Giriniz'"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Vergi Numarası" prop="vergiNo">:
                  <el-input size="small" v-model="musteriForm.vergiNo"
                            v-mask="'##########################'"
                            :placeholder="'Vergi Numarası Giriniz'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item label="İnternet Adresi" prop="website">:
                  <el-input size="small" v-model="musteriForm.website"
                            :placeholder="'İnternet Adresi Giriniz'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Adres" prop="adres">:
                  <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }"
                            placeholder="Adres Giriniz" v-model="musteriForm.adres">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-collapse style="margin-top: 10px" v-model="activeCollapse">
            <el-collapse-item v-if="ekstralar.length > 0" :title="'Yetkili Kişi Ek Bilgiler'" name="2"
                              v-for="(ekstra, index) in ekstralar"
                              :key="index">
              <template>
                <div>
                  <el-row class="mb-15">
                    <el-col :lg="5" :md="5" :sm="5" :xs="5" class="col-p">
                      <el-form-item label='Ad Soyad' prop="yetkiliAdSoyad">
                        <el-input v-model="ekstra.yetkiliAdSoyad"
                                  placeholder='Ad Soyad Giriniz'></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="5" :sm="5" :xs="5" class="col-p">
                      <el-form-item label='Telefon Numarası' prop="yetkiliTelefon">
                        <el-input v-mask="'+90(###)### ## ##'" v-model="ekstra.yetkiliTelefon"
                                  placeholder='Telefon Numarası Giriniz'></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="5" :sm="5" :xs="5" class="col-p">
                      <el-form-item label='E-posta' prop="yetkiliEposta">
                        <el-input v-model="ekstra.yetkiliEposta"
                                  placeholder='E-posta Giriniz'></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="5" :sm="5" :xs="5" class="col-p">
                      <el-form-item label='Ünvan' prop="yetkiliUnvan">
                        <el-input v-model="ekstra.yetkiliUnvan"
                                  placeholder='Ünvanı Giriniz'></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="2" :md="2" :sm="2" :xs="2" class="col-p">
                      <el-form-item label="Ekle" prop="ekle">
                        <el-button size="medium" type="primary" @click="addNewInfo">+</el-button>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="2" :md="2" :sm="2" :xs="2" class="col-p">
                      <el-form-item label="Çıkar" prop="cikar">
                        <el-button size="medium" type="primary"
                                   @click="subInfo(index)">-
                        </el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </template>
            </el-collapse-item>
            <el-collapse-item v-if="ekstralar.length === 0" :title="'Yetkili Kişi Ek Bilgiler'" name="3">
              <el-row>
                <el-col>
                  <el-button v-if="ekstralar.length === 0" class="m-20" size="mini" type="primary" @click="addNewInfo">
                    +
                  </el-button>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-col>
        <el-col :lg="6" :md="6" :sm="24" :xs="24">
          <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
            <el-collapse-item class="card-shadow--medium" name="8" :title='"Güncelle"'>
              <el-row>
                <el-col :lg="24" :md="24" :sm="24" :xs="24">
                  <el-button size="mini" v-on:click="musteriGuncelle('musteriForm')" class="onayBtn"
                             type="primary">
                    <label>Güncelle</label>
                  </el-button>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import musteriService from '../../../WSProvider/MusteriService'
import notification from '../../../notification'
import functions from '../../../functions'
import EventBus from '@/components/event-bus'


export default {
  name: "BGCelikMusteriGuncelle",
  components: {},
  computed: {
    storeData() {
      return this.$store.getters.getMusteriUpdateData
    }
  },
  watch: {
    storeData(val) {
      this.musteriForm.musteriID = this.$store.getters.getMusteriUpdateData
      this.getMusteriDetay();
    }
  },
  mounted() {
    this.musteriForm.musteriID = this.$store.getters.getMusteriUpdateData
    this.getMusteriDetay();
  },
  data() {
    return {
      loading: false,
      addLoading: false,
      activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
      musteriData: {},
      ekBilgiData: {},
      ekstralar: [],
      musteriForm: {
        musteriID: '',
        unvan: '',
        telefon: '',
        eposta: '',
        adres: '',
        website: '',
        musteriAdi: '',
        vergiNo: '',
        vergiDairesi: '',
        durum: '1',
      },
      rulesMusteri: {
        musteriAdi: [{
          required: true,
          message: "Lütfen Firma Adı Giriniz",
          trigger: 'blur'
        }],/*
                    unvan: [{
                        required: true,
                        message: "Lütfen Firma Unvanı Giriniz",
                        trigger: 'blur'
                    }],
                    telefon: [{
                        required: true,
                        message: "Lütfen Telefon Numarası Giriniz",
                        trigger: 'blur'
                    }],
                    eposta: [
                        {required: true, message: "Lütfen E-posta Giriniz", trigger: 'blur'},
                        {type: 'email', message: this.$t('src.views.apps.messages.enterValidMail'), trigger: 'blur'},
                    ],
                    adres: [{
                        required: true,
                        message: "Lütfen Adres Giriniz",
                        trigger: 'blur'
                    }],
                    vergiDairesi:[{
                        required: true,
                        message: "Lütfen Vergi Dairesi Giriniz",
                        trigger: 'blur'
                    }],
                    vergiNo: [{
                        required: true,
                        message: "Lütfen Vergi Numarası Giriniz",
                        trigger: 'blur'
                    }]*/
      },
    }
  },
  methods: {
    addNewInfo() {
      this.ekstralar.push({yetkiliTelefon: '', yetkiliEposta: '', yetkiliAdSoyad: '', yetkiliUnvan: ''});
    },
    subInfo(index) {
      this.ekstralar.splice(index, 1);
      if (this.secili && this.ekstralar.length == 0) {
        this.addNewInfo()
      }
    },
    getMusteriDetay() {
      this.ekBilgiData = {}
      this.ekstralar = []
      try {
        this.loading = true;
        musteriService.musteriDetay(this.musteriForm.musteriID).then(response => {
          if (response.status == 200) {
            localStorage.setItem("userDataBGSurec", response.token);
            this.musteriForm.unvan = response.data.unvan
            this.musteriForm.telefon = response.data.telefon
            this.musteriForm.eposta = response.data.eposta
            this.musteriForm.adres = response.data.adres
            this.musteriForm.website = response.data.website
            this.musteriForm.musteriAdi = response.data.musteriAdi
            this.musteriForm.vergiNo = response.data.vergiNo
            this.musteriForm.vergiDairesi = response.data.vergiDairesi
            this.musteriForm.durum = response.data.durum
            if (response.data.ekBilgiler.length > 0) {
              this.ekstralar = response.data.ekBilgiler
            }
            this.musteriData = response.data
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        this.loading = false;
      }
    },
    musteriGuncelle(formName) {
      const updateEkBilgi = {}
      var update = functions.findObjectDifference(this.musteriData, this.musteriForm);
      this.ekstralar.forEach((item, i) => {
        if (item.yetkiliAdSoyad) {
          updateEkBilgi[`ekBilgi][${i}][yetkiliAdSoyad`] = item.yetkiliAdSoyad
          updateEkBilgi[`ekBilgi][${i}][yetkiliEposta`] = item.yetkiliEposta
          updateEkBilgi[`ekBilgi][${i}][yetkiliTelefon`] = item.yetkiliTelefon
          updateEkBilgi[`ekBilgi][${i}][yetkiliUnvan`] = item.yetkiliUnvan
        }
      })
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          this.$confirm("Müşteriyi güncellemek istediğinize emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
            confirmButtonText: this.$t("src.views.apps.genel.yes"),
            cancelButtonText: this.$t("src.views.apps.genel.no"),
            type: 'warning'
          }).then(() => {
            this.addLoading = true;
            var update = functions.findObjectDifference(this.musteriData, this.musteriForm);
            musteriService.musteriGuncelle(this.musteriForm.musteriID, this.musteriForm, this.ekstralar, 2).then((response) => {
              if (response.status == 200) {
                localStorage.setItem("userDataBGSurec", response.token)
                notification.Status("success", this, response.msg)
                EventBus.$emit("musterilistesi", true)
                functions.sayfaKapat("bgcelikmusteri-guncelle", this);
                this.resetForm(formName)
              }
              this.addLoading = false;
            }).catch(err => {
              if (err.responseJSON) {
                let error = err.responseJSON
                if (error.status == 401) {
                  notification.Status(150, this);
                } else notification.Status("errorMsg", this, error.msg)
              }
              this.addLoading = false;
            })
          }).catch((e) => {
          })
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            })
            this.addLoading = false
          }
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}

</script>
